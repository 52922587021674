import React, { useState, useEffect } from "react";
import useAxios from "../../utils/useAxios";
import Sidebar from "../../components/Parent/Sidebar";
import Header from "../../components/Parent/Header";
import { useNavigate } from "react-router-dom";
import config from "../../utils/config";

const monthsMapping = {
    "January": "Janvier",
    "February": "Février",
    "March": "Mars",
    "April": "Avril",
    "May": "Mai",
    "June": "Juin",
    "July": "Juillet",
    "August": "Août",
    "September": "Septembre",
    "October": "Octobre",
    "November": "Novembre",
    "December": "Décembre"
};

const BestStudentStatistics = () => {
    const [schools, setSchools] = useState([]);
    const [selectedSchoolId, setSelectedSchoolId] = useState('');
    const [statistics, setStatistics] = useState([]);
    const [monthName, setMonthName] = useState('');
    const [year, setYear] = useState('');

    const axios = useAxios();
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the list of schools
        const fetchSchools = async () => {
            try {
                const response = await axios.get(`/schools/`);
                setSchools(response.data);
            } catch (error) {
                console.error('Error fetching schools:', error);
            }
        };

        fetchSchools();
    }, []);

    useEffect(() => {
        if (selectedSchoolId) {
            const fetchStatistics = async () => {
                try {
                    const response = await axios.get(`/statistics/${selectedSchoolId}/`);
                    setStatistics(response.data.statistics);
                    setMonthName(monthsMapping[response.data.month] || response.data.month);
                    setYear(response.data.year);
                } catch (error) {
                    console.error('Error fetching student statistics : ', error);
                }
            };

            fetchStatistics();
        }
    }, [selectedSchoolId]);

    const handleSchoolChange = (event) => {
        setSelectedSchoolId(event.target.value);
    };

    const handleViewDetails = (stat) => {
        navigate(`/dashboard/parent/best/statistic/${stat.student.id}`, { state: { stat, monthName, year } });
    };

    return (
        <div className="container-xxl position-relative bg-white d-flex p-0">
            <Sidebar />
            <div className="content">
                <Header />

                <div className="container-fluid pt-4 px-4">
                    <h2>Performance Des Meilleurs Elèves Par Ecoles</h2>
                    
                        <select value={selectedSchoolId} onChange={handleSchoolChange} className="form-select">
                            <option value="">Selectionner l'école</option>
                            {schools.map((school) => (
                                <option key={school.id} value={school.id}>{school.name}</option>
                            ))}
                        </select>
                    <h5 className="my-4">Statistiques pour {monthName} {year}</h5>
                    <div className="row">
                        {statistics.map((stat) => (
                            <div className="col-sm-12 col-xl-4 mb-2" key={stat.student.id}>
                                <div className="rounded h-100">
                                    <div className="bg-light text-center p-4">
                                        <img 
                                            className="img-fluid rounded-circle mx-auto mb-4" 
                                            src={config.baseUrl + stat.student.image} 
                                            alt={`${stat.student.first_name} ${stat.student.last_name}`} 
                                            style={{ width: "100px", height: "100px" }} 
                                        />
                                        <h5 className="mb-1">{stat.student.first_name} {stat.student.last_name}</h5>
                                        <p>{stat.student.classe_infos.name}</p>
                                        <p>{stat.student.matricule}</p>
                                        <button 
                                            className="btn btn-primary"
                                            onClick={() => handleViewDetails(stat)}
                                        >
                                            Voir Détails
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BestStudentStatistics;
