import React, { useState, useEffect } from "react";
import api from "../utils/api";
import { useNavigate } from "react-router-dom";

const swal = require("sweetalert2");

function ForgotPasswordPage() {
  useEffect(() => {
    document.title = "Réinitialisation Mot De Passe | School Liaison Guinée";
  }, []);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Réinitialiser les erreurs
    setEmailError("");
    setMessage("");

    // Vérifier le champ email
    if (!email) {
      setEmailError("Veuillez saisir votre adresse email");
      return;
    }

    setIsLoading(true); // Activer l'indicateur de chargement

    try {
      const response = await api.post('/send-reset-code/email/', { email });

      if (response.data.success) {
        navigate(`/verify-reset-code?email=${encodeURIComponent(email)}`);
        swal.fire({
          title: "Un code de vérification a été envoyé à votre adresse e-mail.",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        setEmailError(response.data.message || "Email introuvable. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du code:", error);
      setEmailError("Email introuvable. Veuillez réessayer.");
    } finally {
      setIsLoading(false); // Désactiver l'indicateur de chargement après la tentative d'envoi
    }
  };

  return (
    <div>
      <section className="vh-100">
        <div className="container py-3 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src="img/login.webp"
                      alt="login form"
                      className="img-fluid"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <span className="h4 fw-bold mb-0 text-uppercase">
                            Récupération du mot de passe
                          </span>
                        </div>
                        <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>
                          Saisissez l'adresse e-mail que vous avez fournie lorsque vous vous êtes inscrit. Un message avec un code de confirmation sera envoyé à votre adresse e-mail.
                        </h5>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="email">
                            Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            className={`form-control form-control-lg ${emailError ? "is-invalid" : ""}`}
                            name="email"
                            placeholder="Saisir votre adresse email"
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {emailError && <div className="text-danger">{emailError}</div>}
                        </div>
                        {message && <div className="text-success mb-3">{message}</div>}
                        <div className="pt-1 mb-4">
                          <button
                            className="btn btn-dark btn-lg btn-block text-uppercase"
                            type="submit"
                            disabled={isLoading} // Désactiver le bouton pendant le chargement
                          >
                            {isLoading ? (
                              <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Chargement...</span>
                              </div>
                            ) : (
                              "Envoyer le code"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ForgotPasswordPage;
