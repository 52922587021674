import React from 'react';

export const CheckboxComponent = ({ studentId, category, selectedNotifications, handleNotificationChange }) => (
  <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }} className="mx-1">
    <input
      type="checkbox"
      id={`${studentId}-${category.id}`}
      checked={selectedNotifications[studentId]?.[category.id] || false}
      onChange={() => handleNotificationChange(studentId, category.id)}
      style={{ marginRight: '5px' }} 
    />
    <label htmlFor={`${studentId}-${category.id}`} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {category.name}
    </label>
  </div>
);
