import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import useAxios from "../../utils/useAxios";
import Header from "../../components/Parent/Header";
import Sidebar from "../../components/Parent/Sidebar";
import Spinner from "../../components/Spinner";
import config from "../../utils/config";
import { encryptParam } from "../../utils/crypto";

const baseUrl = config.baseUrl;

function ParentStudentManagement() {
  useEffect(() => {
    document.title = "Gestion des Elèves";
  }, []);

  const token = localStorage.getItem("authTokens");
  const axiosInstance = useAxios();
  const [students, setStudents] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [loadingImages, setLoadingImages] = useState({});
  const [loading, setLoading] = useState(true);

  const decoded = jwtDecode(token);
  const profile_id = decoded.user_id;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/profile/${profile_id}/student-list/`);
        const studentsData = response.data;
        setStudents(studentsData);

        const initialImageUrls = {};
        studentsData.forEach(student => {
          student.students.forEach(std => {
            initialImageUrls[std.id] = baseUrl + std.image;
          });
        });
        setImageUrls(initialImageUrls);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);


  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />
      <div className="content">
        <Header />
        <div className="container-fluid pt-4 px-4">
          <div className="mb-2">
            {/* <h4 className="text-uppercase">Mes élèves</h4> */}
            <Link to="add-student" className="btn btn-primary text-uppercase">
              Ajouter Un Elève
            </Link>
          </div>
          <div className="row g-4">
            {loading ? (
              <Spinner />
            ) : (
              students.map((student) => (
                student.students.map((std) => (
                  <Link 
                    className="col-sm-6 col-xl-3" 
                    key={std.id}
                    to={`/dashboard/parent/student/${encryptParam(std.id)}`}
                  >
                    <div className="bg-light rounded p-4 text-center position-relative">
                      <div className="position-relative d-inline-block">
                        {loadingImages[std.id] ? (
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <img 
                            className="img-fluid rounded-circle mx-auto mb-4" 
                            src={imageUrls[std.id]} 
                            style={{ width: "180px", height: "180px" }}
                            alt="Student"
                          />
                        )}
                      </div>
                      <div className="my-1">
                        <h5 className="mb-1">{std.first_name}</h5>
                        <h5 className="mb-1">{std.last_name}</h5>
                        <h6 className="mb-1">{std.matricule}</h6>
                        <h6 className="mb-1">{std.classe_infos.name}</h6>
                        <h6 className="mb-1">{std.school_infos.name}</h6>
                      </div>
                    </div>
                  </Link>
                ))
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParentStudentManagement;
