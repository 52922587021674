import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useAxios from '../../utils/useAxios';
import Swal from "sweetalert2";
// import QRCode from 'qrcode.react';

const PaymentPage = () => {
  const location = useLocation();
  const axiosInstance = useAxios();
  const [paymentMethod, setPaymentMethod] = useState('');
  const { student, numMonths } = location.state;
  const pricePerMonth = 20000;
  const totalPrice = numMonths * pricePerMonth;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);

    if (method === 'orange_money') {
      handleOrangeMoneyPayment();
    }

    // else if (method === 'mobile_money') {
    //   handleMTNPayment();
    // }

  };

  const createPayPalOrder = async () => {
    try {
      const response = await axiosInstance.post('/payments/paypal/', {
        student_id: student.id,
        num_months: numMonths,
      });
      return response.data.orderID;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Une erreur s'est produite lors de la création de la commande PayPal",
      });
      throw error;
    }
  };

  const handlePayPalApprove = async (orderID) => {
    try {
      const response = await axiosInstance.post('/payments/paypal/capture/', {
        order_id: orderID,
      });

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Succès',
          text: 'Souscription réussie!',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: "Une erreur s'est produite lors de la capture du paiement",
      });
    }
  };

  // Orange Money Payment
  const handleOrangeMoneyPayment = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post('/om/initier-paiement/', {
        student_id: student.id,
        num_months: numMonths,
      });

      if (response.status === 200) {
        const paymentUrl = response.data.payment_url;
        window.open(paymentUrl, '_blank'); // Ouvre l'URL dans un nouvel onglet
      } else {
        Swal.fire('Erreur', 'Une erreur est survenue lors du paiement Orange Money', 'error');
      }
    } catch (error) {
      Swal.fire('Erreur', 'Une erreur est survenue lors de l\'initiation du paiement', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // mtn phone number validation 
  const validatePhoneNumber = (number) => {
    const phoneNumberPattern = /^[6]\d{8}$/;
    return phoneNumberPattern.test(number);
  };

  // MTN Mobile Money Payment
  const handleMTNPayment = async () => {
    if (!validatePhoneNumber(phoneNumber)) {
      Swal.fire('Erreur', 'Veuillez entrer un numéro de téléphone valide.', 'error');
      return;
    }

    try {
      const response = await axiosInstance.post('/mtn/initier-paiement/', {
        student_id: student.id,
        num_months: numMonths,
        numero_de_telephone: phoneNumber
      });
  
      if (response.status === 200) {
        Swal.fire('Succès', 'Paiement initié. Veuillez confirmer auprès de votre opérateur mobile.', 'success');
      } else {
        Swal.fire('Erreur', 'Une erreur est survenue lors du paiement Mobile Money', 'error');
      }
    } catch (error) {
      Swal.fire('Erreur', 'Une erreur est survenue lors de l\'initiation du paiement', 'error');
    }
  };

  return (
    <div className="container my-4 p-4 bg-light">
      <div className="row">
        <div className="col-md-6">
          <h2 className="mb-2">Details de souscription</h2>
          <div className="row">
            <div className='d-flex'>
              <strong>Durée : </strong>
              {numMonths} Mois
            </div>
          </div>
          <div className="row">
            <div className='d-flex'>
              <strong>Prix Total : </strong>
              {totalPrice} GNF
            </div>
          </div>
        </div>
        <div className="col-md-6">
            <h2 className="my-2">Choisissez un moyen de paiement</h2>
            <div className="d-flex flex-wrap">
              <div className='m-2'>
              <img
                src="/img/payment/orange_money.png"
                alt="Orange Money"
                className={`payment-method ${paymentMethod === 'paypal' ? 'selected' : ''}`}
                onClick={() => handlePaymentMethodChange('orange_money')}
                style={{ cursor: 'pointer', width: '150px' }}
              />
              </div>
              <div className='m-2'>
              <img
                src="/img/payment/mobile_money.png"
                alt="MTN Mobile Money"
                className={`payment-method ${paymentMethod === 'paypal' ? 'selected' : ''}`}
                onClick={() => handlePaymentMethodChange('mobile_money')}
                style={{ cursor: 'pointer', width: '150px' }}
              />
              </div>
              <div className='m-2'>
              <img
                src="/img/payment/paypal.png"
                alt="PayPal"
                className={`payment-method ${paymentMethod === 'paypal' ? 'selected' : ''}`}
                onClick={() => handlePaymentMethodChange('paypal')}
                style={{ cursor: 'pointer', width: '150px' }}
              />
              </div>
              
            </div>

            {paymentMethod === 'paypal' && (
              <div className="mt-4 w-50">
                <PayPalScriptProvider options={{ "client-id": 'AS-AwDlhrvcDtWZQGcRg4BMqszHPNqTBAQGDrl-J4rq82dBPX-j2L8JtmDG-pPfofuOjHE7tHVgUfVS3' }}>
                  <PayPalButtons
                    style={{ layout: "horizontal" }}
                    createOrder={async (data, actions) => {
                      const orderID = await createPayPalOrder();
                      return orderID;
                    }}
                    onApprove={async (data, actions) => {
                      await handlePayPalApprove(data.orderID);
                    }}
                    onCancel={() => {
                      Swal.fire({
                        title: "Vous avez annulé le paiement",
                        icon: "error",
                        toast: true,
                        timer: 4000,
                        position: "top-right",
                        timerProgressBar: true,
                        showConfirmButton: false,
                      });
                    }}
                    onError={(err) => {
                      Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: "Une erreur s'est produite lors du paiement",
                      });
                    }}
                  />
                </PayPalScriptProvider>
              </div>
            )}

            {paymentMethod === 'mobile_money' && (
              <div className='m-3'>
                <h6>Entrez votre numéro de téléphone MTN :</h6>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Numéro de téléphone"
                />
                <button onClick={handleMTNPayment}>Confirmer le paiement</button>
              </div>
            )}

        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
