import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="container-fluid nav-bar bg-light">
        <nav className="navbar navbar-expand-lg navbar-light bg-white p-3 py-lg-0 px-lg-4">
            <Link to="/" className="navbar-brand d-flex align-items-center m-0 p-0 d-lg-none">
                <h1 className="text-primary m-0">School Liaison Guinée</h1>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav me-auto">
                    <Link to="/" className="nav-item nav-link active">Accueil</Link>
                    <Link to="/register" className="nav-item nav-link">Créer un compte</Link>
                    <Link to="/login" className="nav-item nav-link">Se Connecter</Link>
                    <Link to="/assistance" className="nav-item nav-link">Nous Contacter</Link>
                </div>
                <div className="mt-4 mt-lg-0 me-lg-n4 py-3 px-4 bg-primary d-flex align-items-center">
                    <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white" style={{ width: 45 + 'px', height: 45 + 'px' }}>
                        <i className="fa fa-phone-alt text-primary"></i>
                    </div>
                    <div className="ms-3">
                        <p className="mb-1 text-white">Nous Joindre 7j/7</p>
                        <h5 className="m-0 text-secondary">+224 624 00 91 91</h5>
                    </div>
                </div>
            </div>
        </nav>
    </div>
  );
}

export default Navbar;
