import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../utils/useAxios";
import moment from "moment";
import "moment/locale/fr";
import Header from "../../components/Parent/Header";
import Sidebar from "../../components/Parent/Sidebar";
import { decryptParam, encryptParam } from "../../utils/crypto";
import Spinner from "../../components/Spinner"; // Import the Spinner component

function ParentNotificationStudent() {
  moment.locale('fr'); 

  useEffect(() => {
    document.title = "Notifications";
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const axios = useAxios();

  useEffect(() => {
    const fetchNotifications = () => {
      const idDecrypt = decryptParam(id);
      axios.get(`/parents/notifications/student/${idDecrypt}/`)
      .then((res) => {
        setNotifications(res.data);
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        // console.error(error);
        setLoading(false); // Set loading to false in case of error
      });
    };

    fetchNotifications(); 
  }, [id]);

  const handleNotificationClick = async (notificationId) => {
    try {
      await axios.get(`/parents/notifications/${notificationId}/`);
      navigate(`/dashboard/parent/notification/details/${encryptParam(notificationId)}`);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">
            <div className="col-sm-12 col-md-6 col-xl-6">
              <div className="h-100 bg-light rounded p-4">
                {loading ? ( // Show spinner while loading
                  <Spinner />
                ) : notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <div
                      key={notification.id} 
                      onClick={() => handleNotificationClick(notification.id)} 
                      className="d-flex align-items-center border-bottom py-3 notification-item"
                    >
                      <img 
                        className="rounded-circle flex-shrink-0" 
                        src={notification.notification.student_infos.image}
                        alt="" 
                        style={{ width: 40 + "px", height: 40 + "px"}} 
                      />
                      <div className="w-100 ms-3">
                        <div className="d-flex w-100 justify-content-between">
                          <h6 className="mb-0">{notification.notification.student_infos.first_name + " " + notification.notification.student_infos.last_name}</h6>
                          <small>{moment(notification.notification.date).format('LL')}</small>
                          <h6 className="mb-0">
                            {
                              notification.is_read ? 
                              <span className=""> <i className="fa fa-bell me-lg-2"></i> </span> 
                              : <span className="text-danger"> <i className="fa fa-bell me-lg-2"></i> </span>
                            }
                          </h6>
                        </div>
                        <span>{notification.notification.message.substring(0, 25)}...</span> <br />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="p-3 bg-light text-center">
                    <h4>Aucune notification à afficher. Veuillez souscrire pour voir les notifications.</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParentNotificationStudent;
