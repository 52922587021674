import React from "react";
import { StudentWithCategories } from "./StudentWithCategories";

export const StudentsList = ({ students, categoryNotifications, selectedNotifications, handleNotificationChange }) => {
  return students.map((student) => (
    <div key={student.id} className="student-row">
      <StudentWithCategories
        student={student}
        categoryNotifications={categoryNotifications}
        selectedNotifications={selectedNotifications}
        handleNotificationChange={handleNotificationChange}
      />
      <hr className="compact-hr"/>
    </div>
  ));
};
