import React from 'react';
import { Link } from "react-router-dom";


const Services = () => {
  return (
    <div className="container-fluid py-5 px-2 px-lg-0">
      <div className="row g-0">
        <div className="col-lg-3 d-none d-lg-flex">
          <div className="d-flex align-items-center justify-content-center bg-primary w-100 h-100">
            <h1 className="display-3 text-white m-0" style={{ transform: 'rotate(-90deg)' }}>Compagnon de Suivi</h1>
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <div className="ms-lg-5 ps-lg-5">
            <div className="text-center text-lg-start">
              <h6 className="text-secondary text-uppercase">Fonctionnalités</h6>
              <h1 className="mb-5">Explorer Nos Fonctionnalités Clés</h1>
            </div>
            <div className="d-flex flex-wrap justify-content-start">
              {/* Service Item 1 */}
              <div className="bg-light p-4 me-4 mb-4" style={{ flex: '1 0 21%', minWidth: '200px' }}>
                
                <h4 className="mb-3">Suivi des notes</h4>
                <p>Suivez en temps réel les notes de votre enfant pour chaque matière et identifiez facilement ses points forts et ses axes d'amélioration.</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Transparence Totale</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Suivi Continu</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Prise de Décision Informée</p>
                <Link to="/" className="btn bg-white text-primary w-100 mt-2">En savoir plus<i className="fa fa-arrow-right text-secondary ms-2"></i></Link>
              </div>

              {/* Service Item 2 */}
              <div className="bg-light p-4 me-4 mb-4" style={{ flex: '1 0 21%', minWidth: '200px' }}>
                
                <h4 className="mb-3">Présence & Absence</h4>
                <p>Obtenez un compte rendu quotidien des présences et absences de votre enfant pour une gestion efficace de son assiduité.</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Surveillance Facile</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Précision Accrue</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Responsabilisation</p>
                <Link to="/" className="btn bg-white text-primary w-100 mt-2">En savoir plus<i className="fa fa-arrow-right text-secondary ms-2"></i></Link>
              </div>

              {/* Service Item 3 */}
              <div className="bg-light p-4 me-4 mb-4" style={{ flex: '1 0 21%', minWidth: '200px' }}>
                
                <h4 className="mb-3">Bonne Conduite & Inconduite</h4>
                <p>Suivez les comportements positifs et les incidents disciplinaires pour une vision complète de la conduite de votre enfant à l'école.</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Encouragement Positif</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Intervention Précoce</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Dialogue Ouvert</p>
                <Link to="/" className="btn bg-white text-primary w-100 mt-2">En savoir plus<i className="fa fa-arrow-right text-secondary ms-2"></i></Link>
              </div>

              {/* Service Item 4 */}
              <div className="bg-light p-4 mb-4" style={{ flex: '1 0 21%', minWidth: '200px' }}>
                
                <h4 className="mb-3">Satistiques & Performances</h4>
                <p>Accédez à des statistiques détaillées sur les performances, la présence et le comportement de votre enfant pour des analyses approfondies.</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Vue Globale</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Outils d’Analyse</p>
                <p className="text-primary fw-medium"><i className="fa fa-check text-success me-2"></i>Amélioration Continue</p>
                <Link to="/" className="btn bg-white text-primary w-100 mt-2">En savoir plus<i className="fa fa-arrow-right text-secondary ms-2"></i></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
