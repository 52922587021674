import React from 'react';
import { Link } from "react-router-dom";

const Carousel = () => {
  return (
    <div className="container-fluid p-0 mb-5">
      <div id="headerCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">

          {/* Carousel Item 1 */}
          <div className="carousel-item active">
            <img src="img/banner/banner1.jpg" className="d-block w-100" alt="Carousel 1" />
            <div className="carousel-caption d-flex align-items-center justify-content-center" style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-10 col-lg-8">
                    <h1 className="display-3 text-white animated slideInDown mb-4">Le bon suivi pour le meilleur investissement.</h1>
                    <p className="fs-5 fw-medium text-white mb-4 pb-2">Chez School Liaison Guinée, nous comprenons l'importance de l'implication des parents dans la réussite de leurs enfants. </p>
                    <Link to="/" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Play Store</Link>
                    <Link to="/" className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">App Store</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Carousel Item 2 */}
          <div className="carousel-item">
            <img src="img/banner/banner2.jpg" className="d-block w-100" alt="Carousel 2" />
            <div className="carousel-caption d-flex align-items-center justify-content-center" style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-10 col-lg-8">
                    <h1 className="display-3 text-white animated slideInDown mb-4">Le bon suivi pour le meilleur investissement.</h1>
                    <p className="fs-5 fw-medium text-white mb-4 pb-2">Chez School Liaison Guinée, nous comprenons l'importance de l'implication des parents dans la réussite de leurs enfants. </p>
                    <Link to="/" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">Play Store</Link>
                    <Link to="/" className="btn btn-secondary py-md-3 px-md-5 animated slideInRight">App Store</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        {/* Carousel Controls */}
        <button className="carousel-control-prev" type="button" data-bs-target="#headerCarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Précédent</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#headerCarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Suivant</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
