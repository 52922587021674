import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import useAxios from '../../utils/useAxios';
import 'chart.js/auto';
import Sidebar from '../../components/Parent/Sidebar';
import Header from '../../components/Parent/Header';
import { decryptParam } from '../../utils/crypto';
import Spinner from '../../components/Spinner'; // Import the Spinner component
import config from '../../utils/config';


function ParentStatisticDetail() {
  const { id } = useParams();
  const [studentInfo, setStudentInfo] = useState(null);
  const [schoolYearInfo, setSchoolYearInfo] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const axios = useAxios();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const idDecrypt = decryptParam(id);
        const response = await axios.get(`/parents/student/${idDecrypt}/statistics/last-school-year/`);
        setStudentInfo(response.data.student);
        setSchoolYearInfo(response.data.school_year);
        setStatistics(response.data.statistics);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const calculatePercentage = (value, total) => {
    return total > 0 ? ((value / total) * 100).toFixed(0) : 0;
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  const createChartData = (data) => {
    const total = data.reduce((acc, curr) => acc + curr.population, 0);
    return {
      labels: data.map((d) => `${d.name} (${calculatePercentage(d.population, total)}%)`),
      datasets: [
        {
          data: data.map((d) => d.population),
          backgroundColor: data.map((d) => d.color),
        },
      ],
    };
  };

  if (isLoading) {
    return (
      <div className="container-xxl position-relative bg-white d-flex p-0">
        <Sidebar />
        <div className="content">
          <Header />
          <div className="container-fluid pt-4 px-4">
            <Spinner /> {/* Show spinner while loading */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />
      <div className="content">
        <Header />
        <div className="container-fluid pt-4 px-4">
          {!studentInfo || !statistics ? (
            <div className="p-3 bg-light text-center">
              <h4>Aucune statistique disponible. Veuillez souscrire pour voir les statistiques.</h4>
            </div>
          ) : (
            <>
              <div className="p-3 bg-light d-flex align-items-center mb-2">
                <img
                  className="rounded-circle"
                  src={studentInfo.image ? `${config.baseUrl}${studentInfo.image}` : `${config.baseUrl}/media/default.jpg`}
                  alt=""
                  style={{ width: '100px', height: '100px' }}
                />
                <div className="ms-3">
                  <h5 className='text-primary'>{studentInfo.first_name}</h5>
                  <h5 className='text-primary'>{studentInfo.last_name}</h5>
                  <h6>{studentInfo.matricule}</h6>
                  <h6>{studentInfo.class_info.class_name}</h6>
                  <h6>{studentInfo.class_info.school_name}</h6>
                </div>
              </div>
              <hr />

              <div className="row g-4">
                <div className="col-sm-12 col-xl-6">
                  <div className="bg-light rounded h-100 p-4">
                    <h5 className='text-center text-uppercase'>Présence et Absence En Classe</h5>
                    <Pie 
                      options={chartOptions} 
                      data={createChartData([
                        { name: 'Présence', population: statistics.presence, color: 'green' },
                        { name: 'Absence', population: statistics.absence, color: 'red' },
                        { name: 'Autorisée', population: statistics.absence_autorisee, color: 'yellow' }
                      ])}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6">
                  <div className="bg-light rounded h-100 p-4">
                    <h5 className='text-center text-uppercase'>Bonne et Mauvaise Conduite A L'école</h5>
                    <Pie 
                      data={createChartData([
                        { name: 'Bonne', population: statistics.bonne_conduite, color: 'green' },
                        { name: 'Mauvaise', population: statistics.mauvaise_conduite, color: 'red' }
                      ])} 
                      options={chartOptions} 
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6">
                  <div className="bg-light rounded h-100 p-4">
                    <h5 className='text-center text-uppercase'>Participation Active, Normale et Faible En Classe</h5>
                    <Pie 
                      data={createChartData([
                        { name: 'Active', population: statistics.participation_active, color: 'green' },
                        { name: 'Faible', population: statistics.participation_faible, color: 'red' },
                        { name: 'Normale', population: statistics.participation_moyenne, color: 'yellow' }
                      ])} 
                      options={chartOptions} 
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6">
                  <div className="bg-light rounded h-100 p-4">
                    <h5 className='text-center text-uppercase'>Bonne et Mauvaise Moyenne Aux Tests/Evaluations</h5>
                    <Pie 
                      data={createChartData([
                        { name: 'Bonne', population: statistics.bonne_moyenne, color: 'green' },
                        { name: 'Mauvaise', population: statistics.mauvaise_moyenne, color: 'red' }
                      ])} 
                      options={chartOptions} 
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ParentStatisticDetail;
