import React, { useState } from 'react';

export default function SupportPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);

    // Simuler un succès de soumission
    setFormStatus('Votre message a été envoyé avec succès.');

    // Remettre le formulaire à zéro après soumission
    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <div style={styles.container}>
      <h2>Page d'Assistance</h2>
      <p>Si vous avez des questions ou besoin d'assistance, veuillez nous contacter via ce formulaire.</p>
      
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label htmlFor="name">Nom :</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        
        <div style={styles.formGroup}>
          <label htmlFor="email">Email :</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        
        <div style={styles.formGroup}>
          <label htmlFor="message">Message :</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="5"
            required
          />
        </div>

        <button type="submit" style={styles.submitButton}>Envoyer</button>
      </form>
      
      {formStatus && <p className='text-danger'>{formStatus}</p>}

      {/* Section des informations de contact */}
      <div style={styles.contactInfo}>
        <h3>Coordonnées de l'entreprise</h3>
        <p><strong>Téléphone :</strong> +224 624 00 00 91</p>
        <p><strong>Email :</strong> contact@schoolliaisonguinee.com</p>
        <p><strong>Adresse :</strong> Lambangny | Ratoma, Conakry, Guinée</p>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px'
  },
  contactInfo: {
    marginTop: '40px',
    textAlign: 'left',
    backgroundColor: '#f9f9f9',
    padding: '20px',
    borderRadius: '10px'
  }
};
