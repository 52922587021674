import useAxios from "../../utils/useAxios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";

import swal from "sweetalert2";
import { decryptParam } from "../../utils/crypto";

const EditStudent = () => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const { id } = useParams();
  const [classes, setClasses] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [latestSchoolYear, setLatestSchoolYear] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    classe_id: "",
    school_year_id: ""
  });

  useEffect(() => {
    document.title = "Modifier Un Élève";

    const fetchData = async () => {
      try {
        const schoolYearsResponse = await axiosInstance.get("/schoolyears/");
        const schoolYears = schoolYearsResponse.data;
        setSchoolYears(schoolYears);

        if (schoolYears.length > 0) {
          const latestYear = schoolYears[schoolYears.length - 1];
          setLatestSchoolYear(latestYear);

          const classesResponse = await axiosInstance.get(`/classe/?school_year_id=${latestYear.id}`);
          setClasses(classesResponse.data);

          if (id) {
            const idDecrypt = decryptParam(id);
            const studentResponse = await axiosInstance.get(`/student/${idDecrypt}/`);
            const studentSchoolYearId = studentResponse.data.school_year ? studentResponse.data.school_year.id : latestYear.id;
            setFormData((prevFormData) => ({
              ...prevFormData,
              first_name: studentResponse.data.first_name,
              last_name: studentResponse.data.last_name,
              date_of_birth: studentResponse.data.date_of_birth,
              classe_id: studentResponse.data.classe_infos.id,
              school_year_id: studentSchoolYearId
            }));
          }
        }
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formDataToSend = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        date_of_birth: formData.date_of_birth,
        classe_id: formData.classe_id,
        school_year_id: formData.school_year_id
      };

      const idDecrypt = decryptParam(id);
      const response = await axiosInstance.put(`/student/${idDecrypt}/`, formDataToSend);

      if (response.status === 200) {
        navigate("/dashboard/student");
        swal.fire({
          icon: "success",
          title: "Succès",
          text: "Élève modifié avec succès !"
        });
      } else {
        swal.fire({
          title: "Une erreur s'est produite, veuillez réessayer",
          icon: "error",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      swal.fire({
        title: "Une erreur s'est produite, veuillez réessayer",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "school_year_id") {
      const fetchClasses = async () => {
        try {
          const response = await axiosInstance.get(`/classe/?school_year_id=${value}`);
          setClasses(response.data);
        } catch (error) {
          // console.log(error);
        }
      };

      fetchClasses();
    }
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">
              <h3 className="mb-4 text-uppercase">Modifier Un Élève</h3>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="bg-light rounded h-100 p-4">
              <label className="form-label">Année Scolaire</label>
              <select
                id="school_year_id"
                name="school_year_id"
                value={formData.school_year_id}
                onChange={handleChange}
                className="form-select mb-3"
              >
                <option value="" disabled>
                  Choisir une année scolaire
                </option>
                {schoolYears.map((year) => (
                  <option key={year.id} value={year.id}>
                    {year.start_date} - {year.end_date}
                  </option>
                ))}
              </select>
              <label className="form-label">Prenom</label>
              <input
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                className="form-control mb-3"
                placeholder="Exemple : John"
              />
              <label className="form-label">Nom</label>
              <input
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                className="form-control mb-3"
                placeholder="Exemple : Doe"
              />
              <label className="form-label">Date de naissance</label>
              <input
                id="date_of_birth"
                name="date_of_birth"
                value={formData.date_of_birth}
                onChange={handleChange}
                type="date"
                className="form-control mb-3"
              />
              <label className="form-label">Classe</label>
              <select
                id="classe_id"
                name="classe_id"
                value={formData.classe_id}
                onChange={handleChange}
                className="form-select mb-3"
              >
                <option value="" disabled>
                  Choisir une classe
                </option>
                {classes.map((classe) => (
                  <option key={classe.id} value={classe.id}>
                    {classe.name}
                  </option>
                ))}
              </select>
              
              <button
                type="submit"
                className="btn btn-primary m-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Chargement...</span>
                  </div>
                ) : (
                  "Modifier"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <BackToTop />
    </div>
  );
};

export default EditStudent;
