import useAxios from "../../utils/useAxios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Agent/Header";
import Sidebar from "../../components/Agent/Sidebar";
import BackToTop from "../../components/BackToTop";
import swal from "sweetalert2";
import { decryptParam } from "../../utils/crypto";

const EditClasse = () => {
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const { id } = useParams();
  const [school, setSchool] = useState([]);
  const [latestSchoolYear, setLatestSchoolYear] = useState(null);
  const [availableClasses, setAvailableClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    level: "",
    school: "",
    school_year: "", 
  });

  useEffect(() => {
    document.title = "Modifier Une Classe";

    const fetchData = async () => {
      try {
        const schoolResponse = await axiosInstance.get("/school/");
        setSchool(schoolResponse.data);

        const schoolYearsResponse = await axiosInstance.get("/schoolyears/");
        if (schoolYearsResponse.data.length > 0) {
          const latestYear = schoolYearsResponse.data[schoolYearsResponse.data.length - 1];
          setLatestSchoolYear(latestYear);
          setFormData((prevFormData) => ({
            ...prevFormData,
            school_year: latestYear.id,
          }));
        }

        if (id) {
          const idDecrypt = decryptParam(id);
          const classResponse = await axiosInstance.get(`/classe/${idDecrypt}/`);
          const { name, level, school, school_year } = classResponse.data;
          setFormData({ name, level, school, school_year });
          updateAvailableClasses(level);
        }
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData();
  }, [id]);

  const classesByLevel = {
    primaire: [
      "1ere Annee A", "1ere Annee B", "1ere Annee C", 
      "2eme Annee A", "2eme Annee B", "2eme Annee C", 
      "3eme Annee A", "3eme Annee B", "3eme Annee C",  
      "4eme Annee A", "4eme Annee B", "4eme Annee C",  
      "5eme Annee A", "5eme Annee B", "5eme Annee C",  
      "6eme Annee A", "6eme Annee B", "6eme Annee C",  
    ],
    college: [
      "7eme Annee A", "7eme Annee B", "7eme Annee C", 
      "8eme Annee A", "8eme Annee B", "8eme Annee C", 
      "9eme Annee A", "9eme Annee B", "9eme Annee C", 
      "10eme Annee A", "10eme Annee B", "10eme Annee C", 
    ],
    lycee: [
      "11eme S Litteraire 1", "11eme S Litteraire 2", "11eme S Litteraire 3", 
      "11eme S Scientifique 1", "11eme S Scientifique 2", "11eme S Scientifique 3", 
      "12eme S Litteraire 1", "12eme S Litteraire 2", "12eme S Litteraire 3", 
      "12eme S Scientifique 1", "12eme S Scientifique 2", "12eme S Scientifique 3", 
      "T SS 1", "T SS 2", "T SS 3", 
      "T SE 1", "T SE 2", "T SE 3", 
      "T SM 1", "T SM 2", "T SM 3", 
    ],
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const idDecrypt = decryptParam(id);
      const response = await axiosInstance.put(`/classe/${idDecrypt}/`, formData);

      if (response.status === 200 || response.status === 201) {
        navigate("/dashboard/classe");
        swal.fire({
          title: `Classe modifiée avec succès`,
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      } else {
        swal.fire({
          title: "Une erreur s'est produite, veuillez réessayer",
          icon: "error",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      swal.fire({
        title: "Une erreur s'est produite, veuillez réessayer",
        icon: "error",
        toast: true,
        timer: 6000,
        position: "top-right",
        timerProgressBar: true,
        showConfirmButton: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "level") {
      updateAvailableClasses(value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: "",
      }));
    }
  };

  const updateAvailableClasses = (level) => {
    setAvailableClasses(classesByLevel[level] || []);
  };

  return (
    <div className="container-xxl position-relative bg-white d-flex p-0">
      <Sidebar />

      <div className="content">
        <Header />

        <div className="container-fluid pt-4 px-4">
          <div className="row g-4">
            <h3 className="mb-4 text-uppercase">Modifier La Classe</h3>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="bg-light rounded h-100 p-4">
              <label className="form-label">Niveau</label>
              <select
                id="level"
                name="level"
                value={formData.level}
                onChange={handleChange}
                className="form-select mb-3"
              >
                <option value="">Choisir un niveau</option>
                <option value="primaire">Primaire</option>
                <option value="college">Collège</option>
                <option value="lycee">Lycée</option>
              </select>

              <label className="form-label">Classe</label>
              <select
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-select mb-3"
              >
                <option value="" disabled>
                  Choisir une classe
                </option>
                {availableClasses.map((classe) => (
                  <option key={classe} value={classe}>
                    {classe}
                  </option>
                ))}
              </select>

              <label className="form-label">École</label>
              <select
                id="school"
                name="school"
                value={formData.school}
                onChange={handleChange}
                className="form-select mb-3"
              >
                <option value="" disabled>
                  Choisir une école
                </option>
                {school.map((school) => (
                  <option key={school.id} value={school.id}>
                    {school.name}
                  </option>
                ))}
              </select>

              <label className="form-label">Année Scolaire</label>
              <select
                id="school_year"
                name="school_year"
                value={formData.school_year}
                onChange={handleChange}
                className="form-select mb-3"
              >
                {latestSchoolYear && (
                  <option key={latestSchoolYear.id} value={latestSchoolYear.id}>
                    {latestSchoolYear.start_date} - {latestSchoolYear.end_date}
                  </option>
                )}
              </select>

              <button
                type="submit"
                className="btn btn-primary m-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Chargement...</span>
                  </div>
                ) : (
                  "Modifier"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <BackToTop />
    </div>
  );
};

export default EditClasse;
