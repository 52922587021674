import { useEffect, useState } from "react";
import useAxios from "../../utils/useAxios";
import Compressor from 'compressorjs';
import { useNavigate } from "react-router-dom";
import BackToTop from "../../components/BackToTop";
import Sidebar from "../../components/Parent/Sidebar";
import Header from "../../components/Parent/Header";

const swal = require("sweetalert2");

function ProfileAgent() {
    useEffect(() => {
        document.title = "Profile";
    });

    const navigate = useNavigate();
    const axiosInstance = useAxios();
    const [userProfile, setUserProfile] = useState([]);
    const [loading, setLoading] = useState(false);

    const tokenString = localStorage.getItem("authTokens");

    if (tokenString) {
        try {
            const tokenObject = JSON.parse(tokenString);
            var full_name = tokenObject.user.full_name;
            var username = tokenObject.user.username;
            var phone = tokenObject.user.phone;
        } catch (error) {
            // console.error("Invalid token format:", error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get("/profil/");
                setUserProfile(response.data[0]);
            } catch (error) {
                // console.log(error);
            }
        };

        fetchData();
    }, []);


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoading(true);
            const fileName = file.name.split('.').slice(0, -1).join('.');
            const fileExtension = file.name.split('.').pop();

            new Compressor(file, {
                quality: 0.3,
                maxWidth: 800,
                maxHeight: 800,
                success: (compressedResult) => {
                    const newFileName = `${fileName}.${fileExtension}`;
                    const newFile = new File([compressedResult], newFileName, {
                        type: compressedResult.type,
                    });

                    const formDataToSend = new FormData();
                    formDataToSend.append("image", newFile);

                    axiosInstance.post("/profile/photo/", formDataToSend, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(response => {
                        if (response.status === 200) {
                            swal.fire({
                                icon: "success",
                                title: "Succès",
                                text: "Photo de profil modifiée",
                            });
                            setUserProfile(prevProfile => ({ ...prevProfile, image: response.data.image }));
                        } else {
                            // console.log(response.status);
                            swal.fire({
                                icon: "error",
                                title: "Erreur",
                                text: "Une erreur s'est produite, veuillez réessayer",
                            });
                        }
                    })
                    .catch(error => {
                        // console.log(error);
                        swal.fire({
                            icon: "error",
                            title: "Erreur",
                            text: "Une erreur s'est produite, veuillez réessayer",
                        });
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                },
                error(err) {
                    // console.error(err.message);
                    swal.fire({
                        icon: "error",
                        title: "Erreur",
                        text: "La compression de l'image a échoué, veuillez essayer une autre image",
                    });
                    setLoading(false);
                },
            });

            setTimeout(() => {
                if (loading) {
                    setLoading(false);
                    swal.fire({
                        icon: "error",
                        title: "Erreur",
                        text: "Le traitement de l'image prend trop de temps, veuillez essayer une autre image",
                    });
                }
            }, 10000);  // 10 seconds timeout
        }
    };

    return (
        <div className="container-xxl position-relative bg-white d-flex p-0">
            <Sidebar />

            <div className="content">
                <Header />

                <div className="container-fluid pt-4 px-4">
                    <div className="row">
                        <div className="col m-2">
                            <h3 className="text-capitalize">Profile</h3>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-sm-12 col-xl-6">
                            <div className="card bg-light p-4 text-center" >
                                <div class="testimonial-item text-center">
                                    <img
                                        className="img-fluid rounded-circle mx-auto mb-4"
                                        src={userProfile.image}
                                        style={{ width: '150px', height: '150px' }}
                                        // style="width: 100px; height: 100px;"
                                        alt="Profile"
                                    />
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="file"
                                        id="imageInput"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />
                                    <h6 className="text-capitalize fw-bold fs-5">{full_name}</h6>
                                    <p className="fw-bold fs-5">@{username}</p>
                                    <p className="fw-bold fs-5">{phone}</p>
                                    <button
                                        type="button"
                                        className="btn btn-primary text-capitalize my-2"
                                        onClick={() => document.getElementById('imageInput').click()}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                        ) : (
                                            <i className="fa fa-camera" aria-hidden="true"></i>
                                        )} Changer la photo
                                    </button>
                                </div>
                                
                            </div>
                        </div>

                        {/* <div className="col-sm-12 col-xl-6">
                            <div className="bg-light rounded h-100 p-3">
                                <div className="row">
                                    <div className="col-12 p-2">
                                        
                                    </div>
                                    <div className="col-12 p-2">
                                        
                                    </div>
                                    <div className="col-12 p-2">
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <BackToTop />
        </div>
    );
}

export default ProfileAgent;
