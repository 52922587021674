import React from 'react'
import { Link } from "react-router-dom";


function Menu() {
  return (
    <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
            <div className="col-sm-12 col-xl-12">
                <div className="bg-light rounded h-100 p-4">
                  <div className="m-n2">
                    <Link to="/dashboard/agent/" type="button" className="btn btn-dark m-2"><i className="fa fa-home"></i></Link>
                    <Link to="/dashboard/classe/" type="button" className="btn btn-primary m-2"> CLASSES</Link>
                    <Link to="/dashboard/student/" type="button" className="btn btn-dark m-2">ELEVES</Link>
                    <Link to="/dashboard/teacher/" type="button" className="btn btn-primary m-2">PROFESSEURS</Link>
                    <Link to="/dashboard/notification/" type="button" className="btn btn-dark m-2">NOTIFICATIONS</Link>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Menu
