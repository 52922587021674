import React, { useState } from "react";
import api from "../utils/api";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const swal = require("sweetalert2");

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function VerifyCodePage() {
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement

  const query = useQuery();
  const email = query.get("email");

  const navigate = useNavigate();

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setCodeError("");
    setMessage("");

    if (!code) {
      setCodeError("Veuillez saisir le code de vérification");
      return;
    }

    setIsLoading(true); // Activer l'indicateur de chargement

    try {
      const response = await api.post('/confirm-reset-password/email/', { email, code });

      if (response.data.success) {
        navigate(`/resetpassword?email=${encodeURIComponent(email)}&code=${encodeURIComponent(code)}`);
        swal.fire({
          title: "Code de vérification correct. Vous pouvez maintenant réinitialiser votre mot de passe.",
          icon: "success",
          toast: true,
          timer: 6000,
          position: "top-right",
          timerProgressBar: true,
          showConfirmButton: false,
        });
      } else {
        setCodeError(response.data.message || "Le code de vérification est incorrect.");
      }
    } catch (error) {
      console.error("Erreur de vérification du code:", error);
      setCodeError("Le code de vérification est incorrect.");
    } finally {
      setIsLoading(false); // Désactiver l'indicateur de chargement après la tentative de vérification
    }
  };

  return (
    <div>
      <section className="vh-100">
        <div className="container py-3 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">
                  <div className="col-md-6 col-lg-5 d-none d-md-block">
                    <img
                      src="img/login.webp"
                      alt="login form"
                      className="img-fluid"
                      style={{ borderRadius: "1rem 0 0 1rem" }}
                    />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className="card-body p-4 p-lg-5 text-black">
                      <form onSubmit={handleVerifyCode}>
                        <div className="d-flex align-items-center mb-3 pb-1">
                          <span className="h4 fw-bold mb-0 text-uppercase">
                            Vérification du Code
                          </span>
                        </div>
                        <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>
                          Entrez le code de vérification que vous avez reçu par email.
                        </h5>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="code">
                            Code de vérification
                          </label>
                          <input
                            type="text"
                            id="code"
                            className={`form-control form-control-lg ${codeError ? "is-invalid" : ""}`}
                            name="code"
                            placeholder="Saisir le code"
                            onChange={(e) => setCode(e.target.value)}
                            disabled={isLoading} // Désactiver le champ pendant le chargement
                          />
                          {codeError && <div className="text-danger">{codeError}</div>}
                        </div>
                        {message && <div className="text-success mb-3">{message}</div>}
                        <div className="pt-1 mb-4">
                          <button
                            className="btn btn-dark btn-lg btn-block"
                            type="submit"
                            disabled={isLoading} // Désactiver le bouton pendant le chargement
                          >
                            {isLoading ? (
                              <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Chargement...</span>
                              </div>
                            ) : (
                              "Vérifier le code"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VerifyCodePage;
